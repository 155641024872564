//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import OrderList from "@/components/OrderList";
export default {
  name: "Lab",
  components: {
    OrderList
  },
  watch: {
    async lastEventData(val) {
      if (val === null) return;
      await this.$store.dispatch("substrate/getLabAccount");
    }
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      isServicesExist: state => state.substrate.isServicesExist,
      labAccount: state => state.substrate.labAccount,
      lastEventData: state => state.substrate.lastEventData
    }),
    verificationStatus() {
      var _this$labAccount;
      return (_this$labAccount = this.labAccount) === null || _this$labAccount === void 0 ? void 0 : _this$labAccount.verificationStatus;
    },
    computeVerificationStatus() {
      var _this$labAccount2, _this$labAccount3;
      return (_this$labAccount2 = this.labAccount) !== null && _this$labAccount2 !== void 0 && _this$labAccount2.verificationStatus ? "Your lab account's verification status is: ".concat((_this$labAccount3 = this.labAccount) === null || _this$labAccount3 === void 0 ? void 0 : _this$labAccount3.verificationStatus) : "Loading verification...";
    },
    computeStakingStatus() {
      var _this$labAccount4;
      return ((_this$labAccount4 = this.labAccount) === null || _this$labAccount4 === void 0 ? void 0 : _this$labAccount4.stakeStatus) === "Unstaked";
    },
    allowDashboard() {
      var _this$labAccount5, _this$labAccount6;
      if (this.isServicesExist) {
        if (((_this$labAccount5 = this.labAccount) === null || _this$labAccount5 === void 0 ? void 0 : _this$labAccount5.stakeStatus) === "Unstaked" && ((_this$labAccount6 = this.labAccount) === null || _this$labAccount6 === void 0 ? void 0 : _this$labAccount6.verificationStatus) === "Unverified") return false;else return true;
      } else return false;
    }
  },
  methods: {
    async onRegister() {
      this.$router.push({
        name: "lab-registration"
      });
    }
  }
};