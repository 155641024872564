import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import ServerSideDataTable from "@/components/DataTable/ServerSideDataTable";
import { queryDnaSamples, queryDnaTestResults } from "@/lib/polkadotProvider/query/geneticTesting";
import SearchBar from "@/components/DataTable/SearchBar";
import { getOrdersData } from "@/lib/api";
import serviceHandler from "@/lib/serviceHandler";
export default {
  name: "LabOrderHistory",
  components: {
    ServerSideDataTable,
    SearchBar
  },
  mixins: [serviceHandler],
  data: () => ({
    headers: [{
      text: "Date",
      value: "_source.created_at"
    }, {
      text: "Service Name",
      value: "_source.service_info.name"
    }, {
      text: "Specimen Number",
      value: "_source.dna_sample_tracking_id"
    }, {
      text: "Status",
      value: "status"
    }, {
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "center",
      width: "5%"
    }],
    orders: [],
    page: 1,
    pageSize: 10,
    address: "",
    password: "",
    search: "",
    isLoading: false
  }),
  props: {
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.fetchDataOrders();
  },
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet"
    })
  },
  methods: {
    async fetchDataOrders(keyword) {
      this.isLoading = true;
      try {
        const orderList = [];
        const listStatus = ["Rejected", "ResultReady"];
        const orders = await this.dispatch(getOrdersData, this.pair.address, this.page, this.pageSize, keyword);
        for (let order of orders.data) {
          const dna = await queryDnaSamples(this.api, order._source.dna_sample_tracking_id);
          const dnaTestResult = await queryDnaTestResults(this.api, order._source.dna_sample_tracking_id);
          const data = {
            ...order,
            _source: {
              ...order._source,
              dna_sample_status: dna === null || dna === void 0 ? void 0 : dna.status,
              testResult: dnaTestResult,
              created_at: new Date(+order._source.created_at.replaceAll(",", "")).toLocaleDateString("id", {
                day: "2-digit",
                month: "short",
                year: "numeric"
              })
            }
          };
          if (this.isDashboard) {
            if (!listStatus.includes(dna === null || dna === void 0 ? void 0 : dna.status)) orderList.push(data);
          } else {
            if (listStatus.includes(dna === null || dna === void 0 ? void 0 : dna.status)) orderList.push(data);
          }
        }
        this.orders = orderList;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSearch(val) {
      this.page = 1;
      await this.fetchDataOrders(val);
    },
    processOrder(item) {
      this.$router.push({
        name: "lab-dashboard-process-order",
        params: {
          orderId: item._source.id
        }
      });
    },
    buttonClass(item) {
      if (item.status == "Success") {
        return "Success";
      }
      return "btn-sending";
    },
    getStatus(status, testResult) {
      if (status === "Registered") {
        return "Registered";
      }
      if (status === "Arrived") {
        return "Received";
      }
      if (status === "QualityControlled") {
        return "Quality Controlled";
      }
      if (status === "WetWork" && testResult) {
        return "Upload Result";
      }
      if (status === "WetWork") {
        return "Analyzed";
      }
      if (status === "ResultReady") {
        return "Result Ready";
      }
      return status;
    },
    actionButton(status) {
      if (status === "Rejected" || status === "ResultReady") {
        return "Details";
      }
      return "Proceed";
    },
    async handlePageChange(value) {
      this.page = value;
    },
    async handlePageSizeChange(value) {
      this.pageSize = value;
      this.page = 1; // If change page size restart from page 1
    }
  }
};