import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SearchBar",
  props: {
    filteredItems: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: ""
    },
    itemText: {
      type: String,
      default: ""
    },
    label: String,
    withDropdown: Boolean,
    returnObject: Boolean
  },
  computed: {
    showFilter() {
      // Show filter if filter-menu slot has content
      return !!this.$slots["filter-menu"];
    },
    showResults() {
      var _this$filteredItems;
      return this.withDropdown && ((_this$filteredItems = this.filteredItems) === null || _this$filteredItems === void 0 ? void 0 : _this$filteredItems.length) && this.active;
    }
  },
  data: () => ({
    active: true,
    searchQuery: ""
  }),
  methods: {
    onClickOutside() {
      this.active = false;
    },
    onSearchInput(val) {
      this.active = true;
      this.searchQuery = val;
      this.$emit("input", val);
    },
    boldString(str, substr) {
      substr = substr.charAt(0).toUpperCase() + substr.slice(1);
      const strRegExp = new RegExp(substr, "g");
      return str.replace(strRegExp, "<b>" + substr + "</b>");
    },
    onItemSelected(item) {
      const selection = this.returnObject ? item : item[this.itemValue];
      if (!this.itemValue && !this.returnObject) {
        console.error("If you do not set return-object props, please at least set item-value props to return a value");
        return;
      }
      this.$emit("itemSelected", selection);
    }
  }
};