import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c('ServerSideDataTable',{attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"sort-by":['createdAt'],"sort-desc":[true],"loading":_vm.isLoading,"total-item-length":_vm.orders.length,"handle-page-change":_vm.handlePageChange,"handle-page-size-change":_vm.handlePageSizeChange,"current-page":_vm.page,"page-size":_vm.pageSize,"additional-class":"laporan-table"},scopedSlots:_vm._u([{key:"search-bar",fn:function(){return [_c('SearchBar',{attrs:{"label":"Search"},on:{"input":_vm.handleSearch}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getStatus(item._source.dna_sample_status, item._source.testResult))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VContainer,[(item._source.status != 'Cancelled')?_c(VBtn,{class:_vm.buttonClass(item),attrs:{"dark":"","small":"","width":"200"},on:{"click":function($event){return _vm.processOrder(item)}}},[_vm._v(" "+_vm._s(_vm.actionButton(item._source.dna_sample_status ))+" ")]):_vm._e()],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }