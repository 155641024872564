import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(_vm.showFilter)?_c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"bri-filter-btn"},'v-btn',attrs,false),on),[_vm._v(" Filter By "),_c(VIcon,{attrs:{"right":"","size":"24"}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2882133071)},[_vm._t("filter-menu")],2):_vm._e(),_c('div',{staticClass:"search-bar"},[_c(VTextField,{staticClass:"bri-search-field",class:{ 'has-filter': _vm.showFilter },attrs:{"hide-details":"auto","label":_vm.label,"outlined":"","autocomplete":"off","dense":"","append-icon":"mdi-magnify","color":"primary"},on:{"input":_vm.onSearchInput,"click":function($event){_vm.active = true}}}),(_vm.showResults)?_c('div',{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"search-bar__results elevation-5 mt-3 position-absolute rounded"},_vm._l((_vm.filteredItems),function(item,idx){return _c('div',{key:idx,staticClass:"search-bar__item",on:{"click":function($event){return _vm.onItemSelected(item)}}},[(_vm.$slots.item || _vm.$scopedSlots.item)?_vm._t("item",null,{"item":item,"index":idx}):_c('div',{staticClass:"py-2 px-4",domProps:{"innerHTML":_vm._s(_vm.boldString(item[_vm.itemText], _vm.searchQuery))}})],2)}),0):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }