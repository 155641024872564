//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: [Number, String],
    change: Function
  },
  data: () => ({
    numOfEntriesSelect: [5, 10, 15, 20]
  }),
  computed: {
    numOfEntries: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("select", val);
      }
    }
  }
};