//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NumOfEntriesSelect from "./NumOfEntriesSelect";
export default {
  name: "ServerSideDataTable",
  components: {
    NumOfEntriesSelect
  },
  props: {
    headers: Array,
    items: Array,
    totalItemLength: Number,
    searchLabel: String,
    search: String,
    additionalClass: String,
    customFilter: Function,
    handlePageChange: Function,
    handlePageSizeChange: Function,
    sortBy: Array,
    itemKey: {
      type: String,
      default: "id"
    },
    sortDesc: Array,
    loading: Boolean,
    loadingText: String,
    pageSize: Number,
    expandedValue: {
      type: Array,
      default: () => []
    },
    currentPage: Number,
    hideEntries: Boolean,
    hideFooter: Boolean,
    singleExpand: {
      type: Boolean,
      default: true
    },
    expand: Boolean
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      // watch it
      if (newVal != oldVal) console.log("Change page!");
      this.page = newVal;
    }
  },
  data: () => ({
    page: 1,
    expanded: [],
    entriesPerPage: 10
  }),
  mounted() {
    this.page = this.currentPage;
    this.entriesPerPage = this.pageSize;
    this.expanded = this.expandedValue;
  },
  computed: {
    dataTableSearchVal: {
      get() {
        return this.search;
      },
      set(val) {
        console.log(val);
        this.$emit("input", val);
      }
    },
    pageCount() {
      return Math.ceil(this.totalItemLength / this.entriesPerPage);
    },
    computedLoadingText() {
      return this.loadingText ? this.loadingText : "Loading.. Please wait";
    }
  },
  methods: {
    clickedRow(_item, slot) {
      if (!this.expand) return;
      slot.expand(!slot.isExpanded);
      this.$emit("onExpanded", !slot.isExpanded, _item.countryId); // Please add more option later on this params
    },

    setEntriesPerPage(val) {
      this.entriesPerPage = val;
    },
    defaultFilter(value, search /*item*/) {
      return value != null && search != null && typeof value !== "boolean" && value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
    },
    /**
     * Footer Helper functions
     */
    from() {
      if (this.totalItemLength == 0) {
        return 0;
      }
      return this.entriesPerPage * (this.page - 1) + 1;
    },
    to() {
      if (this.page * this.entriesPerPage > this.totalItemLength) {
        return this.totalItemLength;
      }
      return this.page * this.entriesPerPage;
    },
    total() {
      return this.totalItemLength;
    }
    /** ----------------- */
  }
};